/* Required Dependency
 * jQuery
 * jquery-fn.js
 * platform-detect.js,
 * screen-size.js
 */

runFunction(function() {
    window.deviceUtils || (window.deviceUtils = {});

    const SreenSize = rwd.ScreenSize,
          htmlElement = document.querySelector('html'),
          bodyElement = document.querySelector('body'),
          attrPrefix = 'data-platform-',
          isScrollBlocked = 'isScrollBlocked',
          browsers = {
            chrome: 'chrome',
            firefox: 'firefox',
            opera: 'opera',
            safari: 'safari',
            ie: 'ie',
            edge: 'edge'
          },
          data = [
            'name',
            'version',
            'layout',
            'os',
            'description',
            'product'
          ];

    const utils = {
        dataLength: function(data) {
            return data.length;
        },
        lowerCase: function(string) {
            if (string && typeof string === 'string') {
                return string.toLowerCase();
            }
        },
        cb: function(cb) {
            return typeof cb === 'function' ? cb() : null;
        },
        isMobile: function() {
            return SreenSize.is(SreenSize.SIZES.XSMALL) ||
                   SreenSize.is(SreenSize.SIZES.SMALL);
        },
        isTablet: function() {
            return SreenSize.is(SreenSize.SIZES.MEDIUM);
        },
        isDesktop: function() {
            return SreenSize.is(SreenSize.SIZES.LARGE);
        },
        isChrome: function() {
            return this.checkBrowser(browsers.chrome);
        },
        isFirefox: function() {
            return this.checkBrowser(browsers.firefox);
        },
        isOpera: function() {
            return this.checkBrowser(browsers.opera);
        },
        isIe: function() {
            return this.checkBrowser(browsers.ie);
        },
        isEdge: function() {
            return this.checkBrowser(browsers.edge);
        },
        isSafari: function() {
            return this.checkBrowser(browsers.safari);
        },
        isAndroid: function() {
            return /(android)/i.test(navigator.userAgent);
        },
        isIpod: function() {
            return /(ipod)/i.test(navigator.userAgent)
        },
        isIpad: function() {
            return /(ipad)/i.test(navigator.userAgent);
        },
        isIphone: function() {
            return /(iphone)/i.test(navigator.userAgent);
        },
        isIosDevice: function() {
            return this.isIphone() || this.isIpod() || this.isIpad();
        },
        isHarmony: function() {
            return /(HarmonyOS)/i.test(navigator.userAgent);
        },
        checkBrowser: function(platformName) {
            return this.lowerCase(platform.name) === platformName ? true : false;
        },
        sizes: function() {
            return SreenSize.SIZES;
        },
        updateDeviceUtils: function() {
            const _this = utils;
            return $.extend(deviceUtils, {
                isMobile: _this.isMobile(),
                isTablet: _this.isTablet(),
                isDesktop: _this.isDesktop()
            });
        },
        platformDetails: function() {
            let obj = {};
            data.map(function(value) {
                return obj[value] = platform[value]
            });
            return obj;
        },
        setHtmlAttributes: function() {
            let index = 0;
            for ( index; index < utils.dataLength(data); index++ ) {
                if ( platform[data[index]] !== null ) {
                    let name  = attrPrefix + data[index],
                        value =  platform[data[index]];
                    htmlElement.setAttribute(name, value);
                }
            }
        },
        platformName: function() {
            return {
                isChrome: this.isChrome(),
                isFirefox: this.isFirefox(),
                isOpera: this.isOpera(),
                isSafari: this.isSafari(),
                isIe: this.isIe(),
                isEdge: this.isEdge()
            };
        },
        productName: function() {
            return {
                isAndroid: this.isAndroid(),
                isIpod: this.isIpod(),
                isIpad: this.isIpad(),
                isIphone: this.isIphone(),
                isIosDevice: this.isIosDevice()
            };
        },
        blockBodyScroll: function(cb) {
            const scrollWidth = (window.innerWidth - document.documentElement.clientWidth);
            bodyElement.classList.add(isScrollBlocked);
            bodyElement.style.paddingRight = scrollWidth + "px";
            return this.cb(cb);
        },
        unBlockBodyScroll: function(cb) {
            if ( bodyElement.classList.contains(isScrollBlocked) ) {
                bodyElement.classList.remove(isScrollBlocked);
                bodyElement.style.paddingRight = '';

                let bodyStyle = bodyElement.getAttribute('style'),
                    bodyHasEmptyStyle = typeof bodyStyle === 'string' && bodyStyle === '';

                bodyHasEmptyStyle && bodyElement.removeAttribute('style');

                return this.cb(cb);
            }
        },
        orientationType: function(type) {
            return window.matchMedia(type).matches;
        },
        isOrientationPortrait: function() {
            const type = '(orientation: portrait)';
            return this.orientationType(type);
        },
        isOrientationLandScape: function() {
            const type = '(orientation: landscape)';
            return this.orientationType(type);
        },
        onOrientationChange: function(cb) {
            const _this = utils;
            $(document).onPage(rwd.ScreenSize.SIZE_CHANGE_EVENT_NAME, function() {
                $.extend(deviceUtils, {
                    isOrientationLandScape: _this.isOrientationLandScape(),
                    isOrientationPortrait: _this.isOrientationPortrait()
                });
                return _this.cb(cb);
            });
        },
        clickOutside: function() {
            // DOC. https://learn.jquery.com/events/event-extensions/
            var $document = $(document),
                hash = {},
                setup = false;
            var CLICKOUT = function(type) {
                return {
                    setup: function () {
                        if (setup) return
                        $document[type]('click.clickout-handler', function (event) {
                            $.each(hash, function (index, obj) {
                                var condition = true;

                                $.each(obj.elements, function (i, el) {
                                    if ($(event.target).closest(el).length) {
                                        condition = false;
                                    }
                                });

                                if (condition) {
                                    obj.handler.call($(obj.elements), event);
                                }
                            });
                        });

                        setup = true;
                    },
                    teardown: function () {
                        if (hash) return;
                        $document[type]('click.clickout-handler');
                        setup = false;
                    },
                    add: function (handleObj) {
                        var guid = handleObj.guid;
                        if (!hash.hasOwnProperty(guid)) {
                            hash[guid] = {
                                elements: [this],
                                handler: handleObj.handler
                            };
                        } else {
                            hash[guid].elements.push(this);
                        }
                    },
                    remove: function (handleObj) {
                        delete hash[handleObj.guid];
                    }
                }
            };
            $.event.special.clickout = CLICKOUT("onPage");
            $.event.special.clickoutHeader = CLICKOUT("onHeaderEvent");
            $.event.special.clickoutFooter = CLICKOUT("onFooterEvent")
        },
        initializeDeviceUtils: function() {
            return {
                setHtmlAttributes: this.setHtmlAttributes(),
                platformDetails: this.platformDetails(),
                platformName: this.platformName(),
                productName: this.productName(),
                isMobile: this.isMobile(),
                isTablet: this.isTablet(),
                isDesktop: this.isDesktop(),
                isOrientationPortrait: this.isOrientationPortrait(),
                isOrientationLandScape: this.isOrientationLandScape(),
                blockBodyScroll: this.blockBodyScroll,
                unBlockBodyScroll: this.unBlockBodyScroll,
                onOrientationChange: this.onOrientationChange,
                clickOutside: this.clickOutside(),
                sizes: this.sizes()
            };
        }
    };

    $.extend(deviceUtils, utils.initializeDeviceUtils());

    $(document).onPage(rwd.ScreenSize.SIZE_CHANGE_EVENT_NAME, utils.updateDeviceUtils);
});
